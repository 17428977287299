import React from 'react';

const LogoTent = ({ hasText = false, textColor = 'black', ...props }) => {
  return (
    <svg
      viewBox={`0 0 ${hasText ? 594 : 239} 199`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        maxWidth: hasText ? 594 : 239,
      }}
      {...props}
    >
      <path d="M67.2368 0L59.2363 1.92271V15.8431H67.2368V0Z" fill="#838383" />
      <path
        d="M63.3386 6.72949L0 141.319L38.387 158.623L111.322 199L175.621 178.812L238 172.082L178.5 35.5701L115.161 29.802L63.3386 6.72949Z"
        fill="#E6B18C"
      />
      <path
        d="M111.322 199L178.5 35.5701L238 172.082L175.621 178.812L111.322 199Z"
        fill="#D2A584"
      />
      <path
        d="M174.661 45.1836L170.822 180.331L174.661 179.773V45.1836Z"
        fill="#7B6657"
      />
      <path
        d="M182.338 33.6473L174.66 31.7246V187.464L182.338 185.541V33.6473Z"
        fill="#838383"
      />
      {hasText && (
        <>
          <path
            d="M332.8 174.169C318.445 174.169 306.996 170.156 298.366 162.045C289.737 154.02 285.379 142.494 285.379 127.639H312.293C312.464 134.554 314.088 140.018 317.164 144.031C320.24 148.129 325.195 150.093 332.116 150.093C338.61 150.093 343.309 148.129 346.214 144.287C349.205 140.445 350.657 135.322 350.657 129.005V24.3325H377.657V129.09C377.657 143.519 373.642 154.618 365.524 162.472C357.493 170.242 346.556 174.169 332.8 174.169Z"
            fill={textColor}
          />
          <path
            d="M453.188 175.364C442.337 175.364 432.853 173.486 424.565 169.815C416.277 166.058 409.783 160.765 404.998 153.849C400.214 146.934 397.736 138.481 397.65 128.492H426.103C426.359 135.322 428.837 141.128 433.536 145.824C438.236 150.519 444.729 152.91 453.017 152.91C460.195 152.91 465.834 151.202 470.106 147.787C474.293 144.372 476.429 139.762 476.429 134.042C476.429 127.98 474.549 123.284 470.875 119.954C467.116 116.625 462.245 113.893 456.008 111.758C449.856 109.624 443.192 107.404 436.185 105.014C424.821 101.086 416.192 96.049 410.211 89.9018C404.23 83.7547 401.239 75.5585 401.239 65.3133C401.068 56.6048 403.204 49.1771 407.476 42.9445C411.748 36.712 417.644 31.8456 425.078 28.4305C432.511 25.0154 441.055 23.3079 450.796 23.3079C460.622 23.3079 469.252 25.1008 476.771 28.6012C484.29 32.1017 490.185 37.0535 494.457 43.2861C498.729 49.6039 501.036 57.1171 501.293 65.8256H472.413C472.242 60.6176 470.277 56.0072 466.432 52.0799C462.587 48.0672 457.204 46.1035 450.283 46.1035C444.388 45.9327 439.432 47.3841 435.416 50.4577C431.4 53.4459 429.435 57.8855 429.435 63.6058C429.435 68.5576 430.973 72.3996 434.049 75.3024C437.125 78.2052 441.312 80.5957 446.695 82.5594C451.992 84.5231 458.144 86.6575 464.979 88.8773C472.242 91.4386 478.907 94.3414 484.973 97.6711C491.04 101.001 495.824 105.526 499.498 110.99C503.172 116.539 504.967 123.626 504.967 132.334C504.967 140.018 503.002 147.19 499.071 153.764C495.141 160.338 489.331 165.631 481.555 169.644C473.866 173.4 464.381 175.364 453.188 175.364Z"
            fill={textColor}
          />
          <path
            d="M528.551 175.364L552.646 136.432L528.209 98.354H544.358L561.617 125.162L577.51 98.354H593.402L569.307 137.115L594 175.364H577.851L560.336 148.214L544.443 175.364H528.551Z"
            fill={textColor}
          />
        </>
      )}
    </svg>
  );
};

export default LogoTent;
